
/*
 * VNCtask : VNCtask – the easy to use Task Management & To-Do List application. Stay organized. Anytime! Anywhere!
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

var nativeCreateObjectURL = URL.createObjectURL.bind(URL);
var nativeRevokeObjectURL = URL.revokeObjectURL.bind(URL);

function getScreenConstraints(sendSource, callback) {
  var screen_constraints = {};
  // now invoking native getUserMedia API
  callback(null, screen_constraints);
}

document.addEventListener('pause', function () {
  window.appInBackground = true;
  console.log('[custom.function.js] app is running in background');
});
document.addEventListener('resume', function () {
  window.appInBackground = false;
  console.log('[custom.function.js] app is running in foreground');
});

function hasClass(elem, className) {
  return elem.classList.contains(className);
}

document.addEventListener('deviceready', function () {
  try {
    cordova.plugins.backgroundMode.setDefaults({
      title: 'VNCtask',
      icon: 'icon',
      silent: true,
      text: 'VNCtask is running'
    });
    cordova.plugins.backgroundMode.enable();
    cordova.plugins.backgroundMode.on('activate', function () {
      // cordova.plugins.backgroundMode.disableWebViewOptimizations();
    });
  } catch (ex) {

  }
  try {
    FastClick.attach(document.body);
    console.log("[custom.function.js] attach FastClick");
  } catch (e) {

  }
  try {
    navigator.noAutolock.disableAutolock();
  } catch (e) {

  }


  if (typeof device !== 'undefined') {
    if (device.platform === 'iOS') {
      try {
        window.FirebasePlugin.grantPermission();
      } catch (e) {

      }
      cordova.plugins.iosrtc.registerGlobals();
      window.mobileApp = true;
      document.querySelector('html').classList.remove('safari');
      document.querySelector('html').classList.add('vnctalksafari');
      $(document).on('click', 'a.open-new-window', function (e) {
        var url = $(this).attr('href');
        window.open(url, '_system');
        e.preventDefault();
      });
      // load adapter.js
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "assets/js/adapter-latest.js";
      script.async = false;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
    if (device.platform === 'Android') {
      document.querySelector('html').classList.remove('chrome');
      document.querySelector('html').classList.add('vnctalkchrome');
      $(document).on('click', 'a.open-new-window', function (e) {
        var url = $(this).attr('href');
        navigator.app.loadUrl(url, {
          openExternal: true
        });
        e.preventDefault();
      });
    }
  }

  document.addEventListener('exitButton', function () {

    navigator.notification.confirm(
      'Do you want to quit',
      onConfirmQuit,
      'QUIT TITLE',
      'OK,Cancel'
    );

  }, true);

  function onConfirmQuit(button) {
    if (button === '1') {
      navigator.app.exitApp();
    }
  }
}, false);

if (typeof Notification !== "undefined") {
  var ChromeNotification = Notification;
}